import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import React from 'react';

import ErrorStageIcon from '@/assets/error-stage.svg';

type ErrorStateType = {
  errorIcon?: string | StaticImageData;
  title: string;
  desc?: string;
  imageClassName?: string;
};

const ErrorStage = ({
  errorIcon,
  title,
  desc,
  imageClassName,
}: ErrorStateType) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center px-7">
      <Image
        className={imageClassName}
        src={errorIcon || ErrorStageIcon}
        quality={100}
        alt="error icon"
      />
      <h4 className="mt-5 text-center text-lg font-bold">{title}</h4>
      {desc && <h6 className="mt-2 text-center">{desc}</h6>}
    </div>
  );
};

export default ErrorStage;
