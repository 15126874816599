import React from 'react';

import { TagType } from '@/utils/enum';

import DefaultTag from '../tags/DefaultTag';

const LoadingCardShimmer = () => {
  return (
    <div className="flex border-b border-b-gray-200 p-5">
      <div className="mr-5">
        <div className="h-24 w-24 overflow-hidden rounded-xl border border-gray-200 bg-gray-200"></div>
      </div>
      <div className="grow">
        <div className="h-2 w-20 rounded-full bg-gray-200 text-lg font-bold capitalize"></div>

        <div className="mt-2 h-2 w-full rounded-full bg-gray-200 text-lg font-bold capitalize"></div>
        <div className="mt-2 h-2 w-32 rounded-full bg-gray-200 text-lg font-bold capitalize"></div>

        <div className="hide-scrollbar overflow-x-auto pt-2">
          <DefaultTag
            key={1}
            title={''}
            tagType={TagType.Default}
            className="my-1 mr-2 inline-block h-4 w-14 px-3 text-sm font-normal capitalize"
          />
          <DefaultTag
            key={2}
            title={''}
            tagType={TagType.Default}
            className="my-1 mr-2 inline-block h-4 w-12 px-3 text-sm font-normal capitalize"
          />
          <DefaultTag
            key={3}
            title={''}
            tagType={TagType.Default}
            className="my-1 mr-2 inline-block h-4 w-20 px-3 text-sm font-normal capitalize"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingCardShimmer;
