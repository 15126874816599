import React from 'react';

import { TagType } from '@/utils/enum';

type DefaultTagProps = {
  title: string;
  tagType: TagType;
  className?: string | undefined;
};

const getColor = (tagType: TagType) => {
  switch (tagType) {
    case TagType.Success:
      return 'bg-green-200';

    case TagType.Warning:
      return 'bg-yellow-200';

    case TagType.Error:
      return 'bg-red-200';

    case TagType.Info:
      return 'bg-blue-200';

    default:
      return 'bg-gray-200';
  }
};

const DefaultTag = ({ title, tagType, className }: DefaultTagProps) => {
  const colorType = getColor(tagType);

  return (
    <div
      className={`inline-flex items-center rounded-full py-1.5 text-black ${colorType} ${className}`}
    >
      <span>{title}</span>
    </div>
  );
};

export default DefaultTag;
