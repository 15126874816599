import * as React from 'react';

type ArrowProps = {
  size?: number;
  color?: string;
};

const NextPrevArrow = ({ size = 30, color }: ArrowProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron-right">
        <g id="arrow-down">
          <path
            id="Path 3"
            d="M10 18L16 12L10 6"
            stroke={color || 'white'}
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(NextPrevArrow);
