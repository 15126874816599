import create from 'zustand';

import type CompanyGeoProps from '@/types/CompanyGeo.type';

interface MapboxState {
  selectedMarker?: mapboxgl.MapboxGeoJSONFeature | undefined;
  companiesGeoJson?: CompanyGeoProps;
  setSelectedMarker: (markerInfo?: mapboxgl.MapboxGeoJSONFeature) => void;
  setCompaniesGeoJson: (geoJson: CompanyGeoProps) => void;
}

const useMapboxStore = create<MapboxState>((set) => ({
  selectedMarker: undefined,

  setSelectedMarker: (markerInfo?: mapboxgl.MapboxGeoJSONFeature) => {
    set(() => ({
      selectedMarker: markerInfo,
    }));
  },
  setCompaniesGeoJson: (geoJson: CompanyGeoProps) => {
    set(() => ({
      companiesGeoJson: geoJson,
    }));
  },
}));

export default useMapboxStore;
