import type { CompanyFilterType } from '@/types/Filter.type';
import { getCleanParams } from '@/utils/helper';

export const GetEBook = async (
  email: string,
  gReCaptchaToken: string,
  filter: CompanyFilterType
) => {
  const params: Record<string, any> = {
    ...filter,
  };
  // Clean query for saving on AWS SQS
  params.page = undefined;
  params.type = undefined;

  const query = new URLSearchParams(getCleanParams(params)).toString();
  const res = await fetch(`/api/ebook?${query}`, {
    body: JSON.stringify({
      email,
      gReCaptchaToken,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return res;
};
