import { useTranslation } from 'next-export-i18n';
import React, { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { GetEBook } from '@/lib/api/GetEBook';
import useEBookStore from '@/store/EBookStore';
import useFilterStore from '@/store/FilterStore';

import RoundInput from '../forms/RoundInput';

export default function DownloadEBookForm() {
  const { email, setMessage, setLoading, setEmail, loading, message } =
    useEBookStore((state) => state);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { filterBy } = useFilterStore((state) => state);
  const { t } = useTranslation();

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const handleSubmitToDownload = useCallback(
    async (gReCaptchaToken: string) => {
      if (!email) return;

      setLoading(true);
      const res = await GetEBook(email, gReCaptchaToken, filterBy);
      const { error } = await res.json();

      if (error) {
        setLoading(false);
        setMessage('error', 'ebook.message.error');

        return;
      }
      setLoading(false);
      setEmail('');
      setMessage('success', 'ebook.message.success');
    },
    [email]
  );

  const handleSumitForm = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!executeRecaptcha) {
        return;
      }
      executeRecaptcha('enquiryFormSubmit').then((gReCaptchaToken) => {
        handleSubmitToDownload(gReCaptchaToken);
      });
    },
    [executeRecaptcha, email]
  );

  return (
    <div className="w-64 flex-1 p-6">
      <h3 className="text-3xl">{t('ebook.title')}</h3>
      <h6 className="text-base text-gray-500">{t('ebook.subtitle')}</h6>

      <div className="mt-8">
        <RoundInput
          submitLabel={t('ebook.submitCta')}
          placeholder={t('ebook.subscribeInputPlaceholder')}
          handleSubmit={handleSumitForm}
          alignment="initial"
          inputSize="w-full"
          loading={loading}
          error={!!message.error || !!message.info}
          value={email}
          onChange={onChange}
        />
      </div>

      {message.error && (
        <h6 className="mt-2 text-sm text-red-500">
          <i>{t(message.error)}</i>
        </h6>
      )}
      {message.info && (
        <h6 className="mt-2 text-sm">
          <i>{t(message.info)}</i>
        </h6>
      )}
      {!message.error && !message.info && (
        <h6 className="mt-2 text-sm text-gray-500">
          <i>{t('ebook.noSpamMessage')}</i>
        </h6>
      )}
    </div>
  );
}
