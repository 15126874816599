/* eslint-disable react/display-name */
import 'mapbox-gl/dist/mapbox-gl.css';

import { Transition } from '@headlessui/react';
import type { MutableRefObject } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import type { GeoJSONSource, MapRef } from 'react-map-gl';
import Map, { Layer, Popup, Source } from 'react-map-gl';
import { useResizeDetector } from 'react-resize-detector';

import useAppSettingStore from '@/store/AppSettingStore';
import useFilterStore from '@/store/FilterStore';
import useMapboxStore from '@/store/MapBoxStore';
import type { LocationInfoProps } from '@/types/CompanyGeo.type';
import type { MarkerType } from '@/types/map.model';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent } from '@/utils/enum';
import useGeoJson from '@/utils/hooks/GeoJsonHook';

// import { getBounds } from '@/utils/mapUtils';
import CompanyMiniPreview from '../modal/CompanyMiniPreview';
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from './LayoutClusters';

type CompanyClustersProps = {
  markers: MarkerType[];
  mapRef: MutableRefObject<MapRef | undefined>;
};

const CompanyClusters = ({ markers, mapRef }: CompanyClustersProps) => {
  const { filterBy } = useFilterStore((state) => state);
  const [geoLocations, setGeoLocations] = useState(undefined);
  const { data } = useGeoJson(filterBy);

  const { setSelectedMarker, selectedMarker: popupInfo } = useMapboxStore(
    (state) => state
  );

  const { isCompanyListVisible } = useAppSettingStore((state) => state);

  // const markerBounds = getBounds(markers);

  const onResize = useCallback(() => {
    mapRef?.current?.resize();
  }, []);

  useEffect(() => {
    if (data) {
      const getLocations = async () => {
        // const geos = await getCompaniesGeoLocation(data.data);
        // @ts-ignore
        setGeoLocations(data);
      };

      getLocations();
    }
  }, [data]);

  const { ref: divRef } = useResizeDetector<HTMLDivElement>({
    handleHeight: false,
    // refreshMode: 'debounce',
    // refreshRate: 100,
    onResize,
  });

  const onClick = (event: mapboxgl.MapLayerMouseEvent) => {
    const { innerWidth: width } = window;
    const isSmallScreen = width < 768;

    const feature = event?.features && event?.features![0];
    if (!feature) return;

    const clusterId = feature?.properties?.cluster_id;

    if (clusterId) {
      const mapboxSource = mapRef?.current?.getSource(
        'companies'
      ) as GeoJSONSource;

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        mapRef?.current?.easeTo({
          // @ts-ignore:next-line
          center: feature?.geometry.coordinates,
          zoom,
          offset: [isSmallScreen ? 0 : 150, 0],
          duration: 500,
        });

        logCustomEvent(AnalyticEvent.MapClusterClick);
      });
    } else if (feature.layer.id === 'unclustered-point') {
      const companiesListWidth =
        document?.getElementById('companyList')?.clientWidth || 300;

      if (popupInfo) {
        // ignore when user click on the same marker that currently open
        if (popupInfo.properties?.id === feature.properties?.id) return;

        setSelectedMarker(undefined);
      }

      let centeringOffset: mapboxgl.PointLike = [
        !isCompanyListVisible ? 0 : companiesListWidth / 2,
        -100,
      ];

      if (isSmallScreen) {
        centeringOffset = [0, -200];
      }

      mapRef?.current?.easeTo({
        // @ts-ignore:next-line
        center: feature?.geometry.coordinates,
        offset: centeringOffset,
        duration: 600,
      });

      logCustomEvent(AnalyticEvent.MapPinClick, {
        // @ts-ignore
        company: feature.properties.name,
      });

      setTimeout(() => {
        setSelectedMarker(feature);
      }, 150);
    } else {
      setSelectedMarker(undefined);
    }
  };

  const handleMouseEnter = () => {
    mapRef?.current?.getCanvas().style.setProperty('cursor', 'pointer');
  };

  const handleMouseLeave = () => {
    mapRef?.current?.getCanvas().style.setProperty('cursor', '');
  };

  if (markers.length <= 0) return <div>Loading...</div>;

  // const padding = {
  //   // TODO for bigger screen we need to increase
  //   left: 200,
  //   top: 200,
  //   right: 200,
  //   bottom: 200,
  // };

  return (
    <div ref={divRef} className="relative h-full">
      <Map
        // @ts-ignore:next-line
        ref={mapRef}
        reuseMaps
        interactiveLayerIds={[clusterLayer?.id!, unclusteredPointLayer?.id!]}
        onClick={onClick}
        // initialViewState={{
        //   zoom: 20,
        //   bounds: markerBounds,
        //   fitBoundsOptions: {
        //     // padding map container to make marker look good
        //     padding,

        //     // animation duration when bounds
        //     duration: 800,

        //     // we can use offset to make map full screen and company list is absolute position
        //     // so we can use offset for padding left
        //     offset: [150, 0],
        //   },
        // }}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
        attributionControl={false}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onZoomEnd={() => logCustomEvent(AnalyticEvent.MapZoomInteraction)}
        onMoveEnd={() => logCustomEvent(AnalyticEvent.MapMoveInteraction)}
      >
        {data && (
          <Source
            id="companies"
            type="geojson"
            // data="https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson?mapVersion=1"
            data={geoLocations}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>
        )}

        {popupInfo && (
          <Popup
            focusAfterOpen
            anchor="top"
            // @ts-ignore:next-line
            longitude={Number(popupInfo.geometry.coordinates[0])}
            // @ts-ignore:next-line
            latitude={Number(popupInfo.geometry.coordinates[1])}
            onClose={() => setSelectedMarker(undefined)}
            style={{
              padding: 0,
            }}
            className="p-0"
            offset={[0, 10]}
            closeOnClick={false}
            maxWidth={'280px'}
          >
            <Transition
              appear
              show={popupInfo != null}
              enter="transition duration-200 ease-in-out"
              enterFrom="transform scale-50 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-in-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-50 opacity-0"
              className="origin-top"
            >
              <CompanyMiniPreview
                locationInfo={popupInfo.properties as LocationInfoProps}
              />
            </Transition>
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default CompanyClusters;
