import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-export-i18n';

import Button from '../atoms/Button';

export default function DownloadEBookSuccess({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex w-64 flex-1 flex-col items-center justify-center px-6 py-16">
      <div>
        <CheckCircleIcon className="h-16 w-16 text-green-500" />
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-bold uppercase">
          {t('ebook.success.title')}
        </h2>
      </div>
      <div className="mt-3">
        <p className="text-center text-lg">{t('ebook.success.subtitle')}</p>
      </div>
      <div className="mt-8">
        <Button
          title={t('ebook.success.exploreButton')}
          onClick={handleClose}
        />
      </div>
    </div>
  );
}
