import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';

import NextPrevArrow from './NextPrev';

type PagingProps = {
  handleClick?: (pageSelected: { selected: number }) => void;
  pageCount: number;
  initPage: number;
};

const Pagination = ({ handleClick, pageCount, initPage }: PagingProps) => {
  return useMemo(() => {
    return (
      <div className="my-1 px-5">
        <ReactPaginate
          breakLabel="..."
          previousLabel={
            <div className="rotate-180">
              <NextPrevArrow color="rgb(43 108 176)" />
            </div>
          }
          nextLabel={
            <div className="">
              <NextPrevArrow color="rgb(43 108 176)" />
            </div>
          }
          forcePage={initPage}
          onPageChange={handleClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          // @ts-ignore:next-line
          renderOnZeroPageCount={null}
        />
      </div>
    );
  }, [initPage]);
};

export default Pagination;
