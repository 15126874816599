import { useTranslation } from 'next-export-i18n';
import React, { Fragment, useEffect } from 'react';

import EmptyStageIcon from '@/assets/empty-stage.svg';
import useCompaniesStore from '@/store/CompaniesStore';
import useFilterStore from '@/store/FilterStore';
import type CompanyProps from '@/types/Company.type';
import type { MetaType } from '@/types/Meta.type';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent } from '@/utils/enum';
import useCompany from '@/utils/hooks/CompanyHook';

import VentureItem from '../listTile/Venture';
import LoadingCardShimmer from '../shimmer/LoadingCard';
import ErrorStage from '../stage/ErrorStage';

type HomeContentProps = {
  data: CompanyProps[];
  meta: MetaType;
};

const CompanyList = () => {
  const { t } = useTranslation();
  const { filterBy, currentPage, isHasFilterData } = useFilterStore(
    (state) => state
  );
  const { setCompaniesMeta } = useCompaniesStore((state) => state);
  const { data, error, isLoading } = useCompany(currentPage, filterBy);

  useEffect(() => {
    if (data) {
      setCompaniesMeta(data.meta);

      if (isHasFilterData()) {
        logCustomEvent(AnalyticEvent.SubmitFilter, {
          params: JSON.stringify(filterBy),
        });
      }
    }
  }, [data]);

  if (error) {
    return (
      <ErrorStage title={t('stage.error.title')} desc={t('stage.error.desc')} />
    );
  }

  if (isLoading && !data) {
    return (
      <div className="flex-1">
        {[0, 1, 2].map((item) => (
          <Fragment key={`loading_shimmer_${item}`}>
            <LoadingCardShimmer />
          </Fragment>
        ))}
      </div>
    );
  }

  const { data: companies }: HomeContentProps = data;

  if (companies && companies.length <= 0) {
    return (
      <ErrorStage
        errorIcon={EmptyStageIcon}
        title={t('stage.searchNotFound.title', { type: '' })}
        desc={t('stage.searchNotFound.desc')}
        imageClassName="opacity-50"
      />
    );
  }

  return (
    <div id="companyList" className="flex-1">
      {companies.map((company: CompanyProps) => {
        return (
          <Fragment key={`venture_${company.id}`}>
            <VentureItem company={company} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default CompanyList;
