import useSWRImmutable from 'swr/immutable';

import type { CompanyFilterType } from '@/types/Filter.type';

import { getCleanParams } from '../helper';

const fetcher = (url: string) => fetch(url).then((res) => res.json());
const HOME_API = `${process.env.NEXT_PUBLIC_API_URL}/companies`;

const useCompany = (pageIndex: number, filter: CompanyFilterType) => {
  const params: Record<string, any> = {
    page: pageIndex > 0 ? pageIndex + 1 : undefined,
    ...filter,
  };

  const query = getCleanParams(params);

  return useSWRImmutable(`${HOME_API}${query}`, fetcher);
};

export default useCompany;
