import { maxBy, minBy } from 'lodash';
import type { LngLatBounds } from 'mapbox-gl';
import mapboxgl from 'mapbox-gl';

import type CompanyProps from '@/types/Company.type';
import type { MarkerType } from '@/types/map.model';

export const getMinOrMax = (
  markers: MarkerType[],
  minOrMax: 'max' | 'min',
  latOrLng: 'latitude' | 'longitude'
) => {
  if (minOrMax === 'max') {
    return (maxBy(markers, (value) => value[latOrLng]) as any)[latOrLng];
  }
  return (minBy(markers, (value) => value[latOrLng]) as any)[latOrLng];
};

export const getBounds = (markers: MarkerType[]): LngLatBounds => {
  const maxLat = getMinOrMax(markers, 'max', 'latitude');
  const minLat = getMinOrMax(markers, 'min', 'latitude');
  const maxLng = getMinOrMax(markers, 'max', 'longitude');
  const minLng = getMinOrMax(markers, 'min', 'longitude');

  const southWest = [minLng, minLat] as mapboxgl.LngLatLike;
  const northEast = [maxLng, maxLat] as mapboxgl.LngLatLike;

  return new mapboxgl.LngLatBounds([southWest, northEast]);
};

export const getMarkers = (companies: CompanyProps[]): MarkerType[] => {
  const markers: MarkerType[] = [];
  companies.forEach((company) => {
    const lng = company.specific_locations[0]?.longitude;
    const lat = company.specific_locations[0]?.latitude;

    if (lng && lat) {
      const isValidLat: boolean = lat >= -90 && lat <= 90;

      markers.push({
        id: company.id,
        longitude: isValidLat ? lng : lat,
        latitude: isValidLat ? lat : lng,
      });
    }
  });

  return markers;
};

export const getCompanyLocationMarkers = (
  companies: CompanyProps
): MarkerType[] => {
  const markers: MarkerType[] = [];
  companies.specific_locations.forEach((location) => {
    const lng = location?.longitude;
    const lat = location?.latitude;

    if (lng && lat) {
      const isValidLat: boolean = lat >= -90 && lat <= 90;

      markers.push({
        id: location.id,
        longitude: isValidLat ? lng : lat,
        latitude: isValidLat ? lat : lng,
      });
    }
  });

  return markers;
};
