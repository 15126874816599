import { Transition } from '@headlessui/react';
import React from 'react';

import SimpleOverlay from '@/components/atoms/Overlay';
import useFilterStore from '@/store/FilterStore';

const FilterOverlay = () => {
  const { isVisible, toggleFilter } = useFilterStore((state) => state);

  return (
    <Transition
      className="z-30"
      show={isVisible}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <SimpleOverlay onClick={() => toggleFilter(false)} />
    </Transition>
  );
};

export default FilterOverlay;
