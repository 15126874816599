import useSWRImmutable from 'swr/immutable';

import type { CompanyFilterType } from '@/types/Filter.type';

import { getCleanParams } from '../helper';

const fetcher = (url: string) => fetch(url).then((res) => res.json());
const GEO_JSON_API = `${process.env.NEXT_PUBLIC_API_URL}/pins`;

const useGeoJson = (filter: CompanyFilterType) => {
  const params: Record<string, any> = {
    ...filter,
  };
  const query = getCleanParams(params);
  const url = `${GEO_JSON_API}${query}`;

  return useSWRImmutable(url, fetcher);
};

export default useGeoJson;
