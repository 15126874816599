import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import VerifyIcon from '@/assets/verified-blue.svg';
import type { LocationInfoProps } from '@/types/CompanyGeo.type';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent, TagType } from '@/utils/enum';

import VBImage from '../images/VBImage';
import DefaultTag from '../tags/DefaultTag';

type CompanyMiniPreviewProps = {
  locationInfo: LocationInfoProps;
};

const CompanyMiniPreview = ({
  locationInfo: {
    name,
    slug,
    logo,
    isVerified,
    founded,
    location,
    shortDesc,
    businessModels,
  },
}: CompanyMiniPreviewProps) => {
  return (
    <div className="relative">
      <Link href={`/${slug}`}>
        <a className="company-mini-prev-container block">
          <div className="header">
            <div className="cover">
              <div className="mx-auto h-20 w-20 overflow-hidden rounded-full border border-gray-200 bg-slate-200">
                {logo && (
                  <VBImage
                    src={logo}
                    size={80}
                    alt={'image'}
                    style={{
                      borderRadius: '100px',
                      border: '2px solid white',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="info">
            <div className="mt-3 flex flex-row content-center items-center justify-center">
              <h1 className="text-lg font-medium text-black">{name}</h1>
              {isVerified && (
                <div className="ml-1 flex">
                  <Image
                    className="flex-none"
                    src={VerifyIcon}
                    alt="verified"
                    width={20}
                    height={20}
                  />
                </div>
              )}
            </div>
            <h4 className="py-1 text-sm capitalize">
              {location} | {founded}
            </h4>
            <p className="text-sm line-clamp-3">{shortDesc}</p>
          </div>
          <div className="hide-scrollbar flex flex-row justify-center space-x-2 overflow-x-auto px-5 pt-2 pb-4">
            {businessModels.split(',').map((item) => (
              <DefaultTag
                key={item.toLowerCase()}
                title={item}
                tagType={TagType.Default}
                className="my-1 whitespace-nowrap px-3 text-xs font-normal capitalize"
              />
            ))}
          </div>
        </a>
      </Link>
      <div className="mt-5 flex animate-bounce items-end justify-center">
        <Link href={`/${slug}`}>
          <a
            className="flex h-9 w-9 items-center justify-center rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-center transition-all hover:from-pink-500 hover:to-indigo-500"
            onClick={() => {
              logCustomEvent(AnalyticEvent.ViewByMapPopup);
            }}
          >
            <span className="flex-none">
              <ChevronRightIcon className="w-5 text-white" />
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default CompanyMiniPreview;
