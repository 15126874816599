import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import VerifyIcon from '@/assets/verified-blue.svg';
import type CompanyProps from '@/types/Company.type';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent, TagType } from '@/utils/enum';
import { getCompanyCover, getMainHQLocation } from '@/utils/helper';

import VBImage from '../images/VBImage';
import DefaultTag from '../tags/DefaultTag';

type IVentureItemProps = {
  company: CompanyProps;
};

const VentureItem = ({
  company: {
    slug,
    logo,
    name,
    founding_year: founded,
    short_description: shortDesc,
    industries,
    specific_locations: locations,
  },
}: IVentureItemProps) => {
  const cover = getCompanyCover(logo);
  return (
    <Link href={`/${slug}`}>
      <a
        href={`/${slug}`}
        onClick={() => logCustomEvent(AnalyticEvent.ViewByListing)}
      >
        <div className="flex flex-col border-b border-b-gray-200 p-5 sm:flex-row">
          <div className="mr-5">
            <div className="logo-container relative h-20 w-20 overflow-hidden rounded-xl border border-gray-200 xl:h-24 xl:w-24">
              {cover && <VBImage src={cover} alt={`${name} logo`} />}
            </div>
          </div>
          <div className="mt-3 grow sm:mt-0">
            <div className="flex flex-row content-center items-center">
              <h1 className="text-lg font-bold capitalize text-black">
                {name}
              </h1>
              <div className="ml-2 flex">
                <Image
                  className="flex-none"
                  src={VerifyIcon}
                  alt="verified"
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <h2 className="mt-1 mb-0 flex flex-row items-center text-base font-medium text-gray-800">
              <span>{founded}</span>
              <span className="mx-2 inline-block h-1 w-1 rounded-full bg-gray-800"></span>
              <span className="capitalize">{getMainHQLocation(locations)}</span>
            </h2>
            <p className="mt-1 text-base line-clamp-3">{shortDesc}</p>
            <div className="hide-scrollbar overflow-x-auto pt-2">
              {industries?.slice(0, 3)?.map((industry) => (
                <DefaultTag
                  key={industry.id}
                  title={industry.name}
                  tagType={TagType.Default}
                  className="my-1 mr-2 inline-block px-3 text-sm font-normal capitalize"
                />
              ))}
              {industries && industries?.length > 3 && (
                <DefaultTag
                  title={`+${industries
                    ?.slice(3, industries?.length)
                    ?.length.toString()} more`}
                  tagType={TagType.Default}
                  className="my-1 mr-2 inline-block px-3 text-sm font-normal"
                />
              )}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default VentureItem;
