import {
  // ArrowDownTrayIcon,
  ChevronRightIcon,
  MapIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import type { GetStaticProps, GetStaticPropsContext } from 'next';
import ErrorPage from 'next/error';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import { OrganizationJsonLd } from 'next-seo';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { MapRef } from 'react-map-gl';
import { SWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

// import ButtonWithIcon from '@/components/atoms/ButtonWithIcon';
import FabButton from '@/components/atoms/FabButton';
import CompanyList from '@/components/list/CompanyList';
import CompanyClusters from '@/components/map/CompanyClusters';
import DownloadEBookDialog from '@/components/organisms/DownloadEBookDialog';
import Pagination from '@/components/paging/Pagination';
import FilterOverlay from '@/layouts/FilterOverlay';
import Footer from '@/layouts/Footer';
import { Meta } from '@/layouts/Meta';
import useAppSettingStore from '@/store/AppSettingStore';
import useCompaniesStore from '@/store/CompaniesStore';
// import useEBookStore from '@/store/EBookStore';
import useFilterStore from '@/store/FilterStore';
import useMapboxStore from '@/store/MapBoxStore';
import { Main } from '@/templates/Main';
import type CompanyProps from '@/types/Company.type';
import type { MetaType } from '@/types/Meta.type';
import { logCustomEvent } from '@/utils/analytic';
import { AppConfig } from '@/utils/AppConfig';
import { classes } from '@/utils/classes';
import { AnalyticEvent } from '@/utils/enum';
import { getBounds, getMarkers } from '@/utils/mapUtils';

const fetcher = (url: string) => fetch(url).then((res) => res.json());
const HOME_API = `${process.env.NEXT_PUBLIC_API_URL}/companies`;
const GEO_JSON_API = `${process.env.NEXT_PUBLIC_API_URL}/pins`;

type IIndexProps = {
  preview?: boolean | undefined;
  fallback: any;
};

type HomeContentProps = {
  data: CompanyProps[];
  meta: MetaType;
};

export const HomeContent = () => {
  const { t } = useTranslation();
  const { data } = useSWRImmutable(HOME_API, fetcher);

  const { updateCompanyLayoutView, isCompanyListVisible } = useAppSettingStore(
    (state) => state
  );

  const { setCurrentPage, currentPage } = useFilterStore((state) => state);
  const { companiesMeta } = useCompaniesStore((state) => state);
  // const { setOpenDownloadEBookDialog } = useEBookStore((state) => state);

  const { selectedMarker } = useMapboxStore((state) => state);

  const router = useRouter();
  const [isListVisible, setListVisibility] = useState(true);
  const [isShowingMap, toggleMap] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [validateResult, setValidateResult] = useState<String>('');
  const mapRef = useRef<MapRef>();

  useEffect(() => {
    if (router.isReady && !isValidated) {
      const { query } = router;
      const VALIDATION_API = `${
        process.env.NEXT_PUBLIC_API_URL
      }/validate-access?code=${encodeURIComponent(
        query?.code ? query.code.toString() : ''
      )}`;
      axios
        .get(VALIDATION_API, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          setIsValidated(true);
          setValidateResult(response.status === 200 ? 'Success' : 'Failed');
        })
        .catch(() => {
          setValidateResult('Failed');
        });
    }
  }, [router]);

  useEffect(() => {
    if (validateResult && validateResult !== 'Success') {
      window.location.href = 'https://slash.co/';
    }
  }, [validateResult]);

  const handleToggleMap = useCallback(
    (isFirstLoad = false) => {
      // We adjust offset base on companies list width to make
      // map popup centering or better position alignment on screen
      const companiesListWidth =
        document?.getElementById('companyList')?.clientWidth || 300;

      const { data: companies }: HomeContentProps = data;

      if (!companies) return;

      const markers = getMarkers(companies);
      const markerBounds = getBounds(markers);
      const padding = {
        // TODO for bigger screen we need to increase
        left: 200,
        top: 200,
        right: 200,
        bottom: 200,
      };

      if (!selectedMarker) {
        setTimeout(() => {
          mapRef?.current?.fitBounds(markerBounds, {
            padding,
            offset: [isCompanyListVisible ? 0 : companiesListWidth / 2, 0],
          });
        }, 500);
      } else {
        let offset: mapboxgl.PointLike | undefined = [
          isCompanyListVisible ? 0 : companiesListWidth / 2,
          -100,
        ];
        if (isFirstLoad) {
          offset = [isCompanyListVisible ? companiesListWidth / 2 : 0, -100];
        }
        setTimeout(() => {
          mapRef?.current?.easeTo({
            // @ts-ignore:next-line
            center: selectedMarker.geometry.coordinates,
            offset,
          });
        }, 500);
      }
    },
    [data, selectedMarker, isCompanyListVisible]
  );

  useEffect(() => {
    document?.getElementById('vb-body')?.classList.add('overflow-hidden');

    setListVisibility(isCompanyListVisible);
    if (!selectedMarker) handleToggleMap(true);

    return () => {
      useAppSettingStore.destroy();
      document?.getElementById('vb-body')?.classList.remove('overflow-hidden');
    };
  }, []);

  const {
    data: companies,
    meta: { pagination },
  }: HomeContentProps = data;

  if (!data || (!router.isFallback && companies.length < 0)) {
    return <ErrorPage statusCode={404} />;
  }

  const WebUrl = process.env.NEXT_PUBLIC_WEB_URL ?? '';
  const companiesTotal = companiesMeta
    ? companiesMeta.pagination.total
    : pagination.total;

  const companiesPageCount = companiesMeta
    ? companiesMeta.pagination.pageCount
    : pagination.pageCount;

  return (
    <Main
      meta={
        <Meta
          title={AppConfig.title}
          description={AppConfig.description}
          canonical={WebUrl}
        />
      }
    >
      <OrganizationJsonLd
        logo={`${WebUrl}/venture-builder-map-logo.png`}
        name={AppConfig.title}
        email={AppConfig.email}
        sameAs={[WebUrl]}
        url={WebUrl}
        foundingDate="2016"
      />

      <div className="home-content flex flex-1">
        <div
          className="absolute z-20 max-h-screen w-full flex-none bg-white transition-transform duration-300 md:w-1/2 xl:w-2/5"
          style={{
            transform: `translate3d(${isListVisible ? 0 : '-100%'},0, 0)`,
            transitionTimingFunction: 'cubic-bezier(0.42, 0, 0.34, 0.9)',
          }}
        >
          <div
            className="left-0 z-10 flex h-full flex-col justify-between overflow-auto"
            style={{
              height: 'calc(100vh - 77px)',
            }}
          >
            <div className="border-b p-5">
              <h1 className="text-lg font-medium">
                {t('filter.ventures', {
                  plural: companiesTotal > 1 ? 's' : '',
                  total: companiesTotal,
                })}
              </h1>
            </div>

            <CompanyList />

            <div className="px-0">
              <Pagination
                handleClick={(pageSelected) => {
                  setCurrentPage(pageSelected.selected);

                  logCustomEvent(AnalyticEvent.PaginationChange, {
                    page: pageSelected.selected,
                  });
                }}
                pageCount={companiesPageCount}
                initPage={currentPage}
              />
              <hr />
              <Footer className="px-5" />
            </div>
          </div>

          <div className="absolute left-full top-3 z-10 ml-3 hidden md:block">
            <button
              className="flex h-9 w-9 items-center justify-center rounded-full  border border-gray-800 bg-slate-500 shadow-sm"
              name="Toggle Company List"
              onClick={() => {
                const isVisible = !isListVisible;
                setListVisibility(isVisible);
                updateCompanyLayoutView();
                handleToggleMap();

                logCustomEvent(AnalyticEvent.HideShowCompanyList, {
                  action: isListVisible
                    ? 'show-company-list'
                    : 'hide-company-list',
                });
              }}
            >
              <span
                className={`flex-none ${isListVisible ? 'rotate-180' : ''}`}
              >
                <ChevronRightIcon className="w-5 text-white" />
              </span>
            </button>
          </div>
        </div>

        <div
          className={classes(
            'flex-1 bg-slate-100 transition duration-300',
            'absolute h-full w-full md:h-auto md:w-auto md:relative top-0 pt-20 md:pt-0',
            isShowingMap ? 'z-40' : ''
          )}
        >
          {companies && (
            <CompanyClusters mapRef={mapRef} markers={getMarkers(companies)} />
          )}
          {/* <div className="absolute top-20 right-0 z-10 mt-4 mr-4 md:top-0">
            <ButtonWithIcon
              title={t('ebook.title')}
              size="sm"
              LeftIcon={ArrowDownTrayIcon}
              extendsClass="h-9 bg-white"
              onClick={() => {
                setOpenDownloadEBookDialog(true);
                logCustomEvent(AnalyticEvent.DownloadEbook);
              }}
            />
          </div> */}
        </div>

        <div className="fixed bottom-5 right-5 z-50 block md:hidden">
          <FabButton onClick={() => toggleMap(!isShowingMap)}>
            <MapIcon className="w-6 text-white" />
          </FabButton>
        </div>

        <FilterOverlay />
      </div>
      <DownloadEBookDialog />
    </Main>
  );
};

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const isPreview = (await context.preview) || false;
  const homeResp = await fetcher(HOME_API);
  const geoJson = await fetcher(GEO_JSON_API);

  return {
    props: {
      isPreview,
      fallback: {
        [HOME_API]: homeResp,
        [GEO_JSON_API]: geoJson,
      },
    },
  };
};

const Index = (props: IIndexProps) => {
  return (
    <SWRConfig value={{ fallback: props.fallback }}>
      <HomeContent />
    </SWRConfig>
  );
};

export default Index;
