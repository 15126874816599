import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import React from 'react';

type LogoProps = {
  src: string | StaticImageData;
  alt: string;
  size?: number;
  style?: React.CSSProperties | undefined;
};

// const myLoader = (imageLoader: ImageLoaderProps) => {
//   return `https://example.com/${imageLoader.src}?w=${imageLoader.width}&q=${
//     imageLoader.quality || 75
//   }`;
// };

const VBImage = (props: LogoProps) => {
  return (
    <Image
      // loader={myLoader}
      src={props.src}
      alt={props.alt}
      width={props.size}
      height={props.size}
      objectFit="contain"
      layout={props.size ? undefined : 'fill'}
      className="bg-white align-middle"
      priority={true}
      style={props.style}
      quality={100}
    />
  );
};

export default VBImage;
