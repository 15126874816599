import type { ReactNode } from 'react';
import React from 'react';

import { classes } from '@/utils/classes';

interface IFabButton {
  children: ReactNode;
  onClick: () => void;
}

const FabButton = ({ onClick, children }: IFabButton) => {
  return (
    <button
      className={classes(
        'flex items-center justify-center rounded-full bg-slate-500 uppercase font-medium text-white hover:bg-slate-600 p-3 shadow-md'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FabButton;
