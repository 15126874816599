import create from 'zustand';

import type { MetaType } from '@/types/Meta.type';

interface ICompaniesStore {
  companiesMeta?: MetaType;
  setCompaniesMeta: (meta: MetaType) => void;
}

const useCompaniesStore = create<ICompaniesStore>((set) => ({
  companiesMeta: undefined,
  setCompaniesMeta: (meta: MetaType) => {
    set(() => ({
      companiesMeta: meta,
    }));
  },
}));

export default useCompaniesStore;
