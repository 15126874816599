import create from 'zustand';
import { persist } from 'zustand/middleware';

interface AppSettingState {
  isCompanyListVisible: boolean;
  updateCompanyLayoutView: () => void;
}

const useAppSettingStore = create<AppSettingState>()(
  persist(
    (set, get) => ({
      isCompanyListVisible: true,
      updateCompanyLayoutView: () => {
        set(() => ({
          isCompanyListVisible: !get().isCompanyListVisible,
        }));
      },
    }),
    { name: 'AppSetting' }
  )
);
export default useAppSettingStore;
