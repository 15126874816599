import Image from 'next/image';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Newsletter from '@/assets/newsletter.svg';
import useEBookStore from '@/store/EBookStore';

import DownloadEBookForm from '../molecules/DownloadEBookForm';
import DownloadEBookSuccess from '../molecules/DownloadEBookSuccess';
import SimplePopup from '../templates/Popup';

const DownloadEBookDialog = () => {
  const {
    reset,
    loading,
    message,
    setOpenDownloadEBookDialog,
    openDownloadEBookDialog,
  } = useEBookStore((state) => state);

  useEffect(() => {
    return reset();
  }, []);

  const handleClose = () => {
    setOpenDownloadEBookDialog(false);
    setTimeout(() => {
      reset();
    }, 1000);
  };

  return (
    <SimplePopup isVisible={openDownloadEBookDialog} handleClose={handleClose}>
      <div className="flex flex-row">
        <div className="hidden w-72 flex-none justify-end bg-slate-500 px-6 pt-8 md:flex">
          <div className="translate-y-2 self-end">
            <Image src={Newsletter} alt="Newsletter Icon" quality={100} />
          </div>
        </div>
        {!loading && message.success ? (
          <DownloadEBookSuccess handleClose={handleClose} />
        ) : (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RE_CAPTCHA_SITE_KEY ?? ''}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: 'head',
              nonce: undefined,
            }}
          >
            <DownloadEBookForm />
          </GoogleReCaptchaProvider>
        )}
      </div>
    </SimplePopup>
  );
};

export default DownloadEBookDialog;
